import BasePlugin from '../BasePlugin'

export default class ChangeApprovalStatus extends BasePlugin {
  async execute () {
    let applicationId = {
      'applicationId': this.context.getModel().id
    }
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/RegisterOfApplication/ChangeApprovalStatusCommand`,
      applicationId,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.$msgbox({
        type: 'info',
        message: 'Заявление закрыто!'
      })
      done()
    })
  }
}
